import { FC, PropsWithChildren } from "react";
import Typography from "_ui-kit/components/Typography";
import { white } from "styles/constants";
import styled from "styled-components";

interface MessageToastProps {
  title?: string;
}

export const MessageToast: FC<PropsWithChildren<MessageToastProps>> = ({ title, children }) => {
  return (
    <Container>
      {
        title && (
          <Typography variant="bodyM16" color={white}>{title}</Typography>
        )
      }
      <Body variant="captionR14" color={white}>{children}</Body>
    </Container>
  )
};

const Container = styled.div`
  width: 100%;
`;

const Body = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
