import { ORDER_STATUS_MAP } from "constants/Orders";
import { FC, useEffect } from "react";
import { toast, ToastContainer } from 'react-toastify';
import { observer } from "mobx-react-lite";
import { gray80, gray30, white, error, info, warning, success, primary } from "styles/constants";
import styled from "styled-components";
import { useAppSelector, useNavigate } from "hooks";
import { userSelectors } from "slices/userSlice";
import GSChatService from "services/GSChatService";
import "react-toastify/dist/ReactToastify.css";
import { MessageToast } from "components/Messages/components";

export const Toasts: FC = observer(() => {
  const currentUser = useAppSelector(userSelectors.currentUser);
  const { navigate } = useNavigate();

  useEffect(() => {
    const onNewChatMessage = ({ message }) => {
      if (message.user.id !== currentUser.id) {
        const order = message.attachments[0]?.type === "order" ? message.attachments[0].order : null;
        const text = message.text || (order ? `Order status: ${ORDER_STATUS_MAP[order.status]}` : '');

        toast(
          <MessageToast title={message.user.name}>
            <span dangerouslySetInnerHTML={{ __html: text }} />
          </MessageToast>, {
          onClick: () => {
            navigate(`orders?chat_id=${message.cid.split(':')[1] || message.cid}`);
          }
        });
      }
    };

    GSChatService.client?.on("message.new", onNewChatMessage);

    return () => {
      GSChatService.client?.off("message.new", onNewChatMessage);
    };
  }, [GSChatService.client]);

  return (
    <StyledToastContainer stacked hideProgressBar autoClose={5000} />
  );
});

const StyledToastContainer = styled(ToastContainer)`
  &.offset {
    .Toastify__toast {
      flex-direction: column-reverse;
    }

    .Toastify__close-button {
      align-self: end;
      margin-bottom: 8px;
    }
  }
  
  .Toastify__toast {
    background: ${white};
    color: ${gray80};
    font-size: 14px;
    line-height: 20px;
    padding: 12px;
  }

  .Toastify__toast-body {
    overflow: hidden;
  }
  
  .Toastify__toast-body > div {
    max-width: 100%;
  }

  .Toastify__toast--default {
    background-color: ${gray80};
    border: 1px solid ${primary};
    color: ${white};
  }
  
  .Toastify__toast--error {
    border: 1px solid ${error};
  }
  
  .Toastify__toast--info {
    border: 1px solid ${info};
  }
  .Toastify__toast--success {
    border: 1px solid ${success};
  }

  .Toastify__toast--warning {
    border: 1px solid ${warning};
  }
  
  .Toastify__close-button > svg {
    width: 20px;
    height: 20px;
    color: ${gray30};
  }
`;
