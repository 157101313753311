import { RequestMethod } from "client/BaseHttpClient";
import Client from "services/Client";

export interface ISkillsItem {
  [code: string]: {
    code: string;
    default_name: string;
  };
}

export interface ICategoriesItem {
  [code: string]: {
    code: string;
    default_name: string;
    adult: boolean;
    color: string;
    icon_url: string;
  };
}

interface APIDictionaryList {
  categories: ICategoriesItem[];
  skills: ISkillsItem[];
}

export const getDictionaryList = () =>
  Client.doRequest<APIDictionaryList>("/api/v1/config", {
    method: RequestMethod.GET,
  });
