import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { getDictionaryList, ICategoriesItem, ISkillsItem } from 'services/DictionaryService';
import { ResponseStatus } from 'types';
import { normalizeDictionary } from 'utils/common';

export interface DictionaryListState {
  dictionaryListStatus: ResponseStatus;
  categories: ICategoriesItem;
  skills: ISkillsItem;
}

const initialState: DictionaryListState = {
  dictionaryListStatus: ResponseStatus.IDLE,
  categories: {},
  skills: {},
};

export const getDictionaryListAsync = createAsyncThunk("auth/getDictionaryListAsync", async () => {
  return await getDictionaryList();
});

export const dictionaryListSlice = createSlice({
  name: "dictionaryList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDictionaryListAsync.pending, (state) => {
        state.dictionaryListStatus = ResponseStatus.LOADING;
      })
      .addCase(getDictionaryListAsync.fulfilled, (state, action) => {
        state.dictionaryListStatus = ResponseStatus.IDLE;
        state.skills = normalizeDictionary(action.payload.skills);
        state.categories = normalizeDictionary(action.payload.categories);
      })
      .addCase(getDictionaryListAsync.rejected, (state) => {
        state.dictionaryListStatus = ResponseStatus.FAILED;
      });
  },
});

export const dictionaryListSelectors = {
  categories: (state: RootState) => state.dictionaryList?.categories,
  skills: (state: RootState) => state.dictionaryList?.skills,
};

export default dictionaryListSlice.reducer;
