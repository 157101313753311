import { ResponseStatus } from "types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";
import { cancelOrder, createOrder, getOrder, payOrder, prolongateOrder } from "services/OrderService";
import { Order } from "types/orders";

export interface OrderCreationProps {
  paxer: string;
  streamStartTime: string;
  durationInSeconds: number;
  description: string;
}
export interface OrderState {
  orderCreationStatus: ResponseStatus | null;
  orderFetchingStatus: ResponseStatus | null;
  orderCancellationStatus: ResponseStatus | null;
  orderPaymentStatus: ResponseStatus | null;
  orderProlongationStatus: ResponseStatus | null;
  activeOrder: Order;
}

const initialState: OrderState = {
  orderCreationStatus: null,
  orderFetchingStatus: null,
  orderCancellationStatus: null,
  orderPaymentStatus: null,
  orderProlongationStatus: null,
  activeOrder: null,
};

export const createOrderAsync = createAsyncThunk("order/createOrderAsync", async (data: OrderCreationProps) => {
  return await createOrder(data);
});

export const getOrderAsync = createAsyncThunk("order/getOrderAsync", async (orderID: string) => {
  return await getOrder(orderID);
});

export const cancelOrderAsync = createAsyncThunk("order/cancelOrderAsync", async (orderID: string) => {
  return await cancelOrder(orderID);
});

export const payOrderAsync = createAsyncThunk("order/payOrderAsync", async (orderID: string) => {
  return await payOrder(orderID);
});

export const prolongateOrderAsync = createAsyncThunk("order/prolongateOrderTokenAsync", async (orderId: string) => {
  return await prolongateOrder(orderId);
});

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    clearOrderData: (state) => {
      state.orderFetchingStatus = null;
      state.activeOrder = null;
    },
    resetOrderCreationStatus: (state) => {
      state.orderCreationStatus = null;
    },
    resetOrderCancellationStatus: (state) => {
      state.orderCancellationStatus = null;
    },
    resetOrderPaymentStatus: (state) => {
      state.orderPaymentStatus = null;
    },
    resetOrderProlongationStatus: (state) => {
      state.orderProlongationStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrderAsync.pending, (state) => {
        state.orderCreationStatus = ResponseStatus.LOADING;
      })
      .addCase(createOrderAsync.fulfilled, (state, action) => {
        state.orderCreationStatus = ResponseStatus.SUCCESS;
        state.activeOrder = action.payload;
      })
      .addCase(createOrderAsync.rejected, (state) => {
        state.orderCreationStatus = ResponseStatus.FAILED;
      })
      .addCase(cancelOrderAsync.pending, (state) => {
        state.orderCancellationStatus = ResponseStatus.LOADING;
      })
      .addCase(cancelOrderAsync.fulfilled, (state) => {
        state.orderCancellationStatus = ResponseStatus.SUCCESS;
      })
      .addCase(cancelOrderAsync.rejected, (state) => {
        state.orderCancellationStatus = ResponseStatus.FAILED;
      })
      .addCase(getOrderAsync.pending, (state) => {
        state.orderFetchingStatus = ResponseStatus.LOADING;
      })
      .addCase(getOrderAsync.fulfilled, (state, action) => {
        state.orderFetchingStatus = ResponseStatus.IDLE;
        state.activeOrder = action.payload;
      })
      .addCase(getOrderAsync.rejected, (state) => {
        state.orderFetchingStatus = ResponseStatus.FAILED;
      })
      .addCase(payOrderAsync.pending, (state) => {
        state.orderPaymentStatus = ResponseStatus.LOADING;
      })
      .addCase(payOrderAsync.fulfilled, (state) => {
        state.orderPaymentStatus = ResponseStatus.SUCCESS;
      })
      .addCase(payOrderAsync.rejected, (state) => {
        state.orderPaymentStatus = ResponseStatus.FAILED;
      })
      .addCase(prolongateOrderAsync.pending, (state) => {
        state.orderProlongationStatus = ResponseStatus.LOADING;
      })
      .addCase(prolongateOrderAsync.fulfilled, (state) => {
        state.orderProlongationStatus = ResponseStatus.SUCCESS;
      })
      .addCase(prolongateOrderAsync.rejected, (state) => {
        state.orderProlongationStatus = ResponseStatus.FAILED;
      });
  },
});

export const orderSelectors = {
  activeOrder: (state: RootState) => state.order.activeOrder,
  activeOrderStatus: (state: RootState) => state.order.activeOrder?.status,
  orderCreationStatus: (state: RootState) => state.order.orderCreationStatus,
  orderCancellationStatus: (state: RootState) => state.order.orderCancellationStatus,
  orderPaymentStatus: (state: RootState) => state.order.orderPaymentStatus,
  orderProlongationStatus: (state: RootState) => state.order.orderProlongationStatus,
};

export const {
  clearOrderData,
  resetOrderCreationStatus,
  resetOrderCancellationStatus,
  resetOrderProlongationStatus,
  resetOrderPaymentStatus
} = orderSlice.actions;

export default orderSlice.reducer;
