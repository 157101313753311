import { PublicProfile } from "models";
import { RequestMethod } from "client/BaseHttpClient";
import { TransactionRawData } from "models/Transaction";
import Client from "./Client";

export interface DonateToUserRequest {
  username: PublicProfile["username"];
  amount: number;
  streamer_id: string;
  stream_id: string;
}

interface WalletBalanceResponse {
  amount: number;
}

interface TransactionHistoryResponse {
  last_evaluated_key: string;
  transactions: TransactionRawData[];
}

interface GetStripeKeysRequest {
  amount: number;
}

interface GetStripeKeysResponse {
  client_secret: string;
}

export const getUserWalletBalance = (): Promise<WalletBalanceResponse> =>
  Client.doRequest(`/api/v1/me/beholder/balance`, {
    method: RequestMethod.GET,
  });

export const donateToUser = (data: DonateToUserRequest) => {
  return Client.doRequest(`/api/v1/paxers/${data.streamer_id}/paxer:donate`, {
    method: RequestMethod.POST,
    data,
  });
}


export const getUserTransactionHistory = (): Promise<TransactionHistoryResponse> =>
  Client.doRequest(`/api/v1/me/beholder/transactions/history?limit=1000`, {
    method: RequestMethod.GET,
  });

export const withdrawUserBalance = (data) =>
  Client.doRequest(`/api/v1/admin/user/balance/withdraw`, {
    method: RequestMethod.POST,
    data,
  });

export const topUpUserBalance = (data) =>
  Client.doRequest(`/api/v1/admin/user/balance/top_up`, {
    method: RequestMethod.POST,
    data,
  });

export const getStripeKeys = (data: GetStripeKeysRequest): Promise<GetStripeKeysResponse> =>
  Client.doRequest(`/api/v1/me/beholder/stripe:createTopUpIntent`, {
    method: RequestMethod.POST,
    data,
  });
