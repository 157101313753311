import { ResponseStatus } from "types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";
import {
  getUserWalletBalance,
  donateToUser,
  DonateToUserRequest,
  topUpUserBalance,
  withdrawUserBalance,
  getUserTransactionHistory,
  getStripeKeys,
} from "services/WalletService";
import { Transaction } from "models/Transaction";
import { convertCentsToDollars, convertDollarsToCents } from "utils/money";

interface RawDonateToUserRequest extends Omit<DonateToUserRequest, "amount"> {
  amountInDollars: number;
}

export interface WalletState {
  userBalanceInCents: number;
  userTransactionsHistory: Transaction[];
  userTransactionsHistoryStatus: ResponseStatus;
  userBalanceStatus: ResponseStatus;
  donateToUserStatus: ResponseStatus;
  userBalanceTopUpStatus: ResponseStatus;
  userBalanceWithdrawStatus: ResponseStatus;
  stripeKeysStatus: ResponseStatus;
  clientSecret: string | null;
}

const initialState: WalletState = {
  userBalanceInCents: null,
  userBalanceStatus: ResponseStatus.IDLE,
  donateToUserStatus: ResponseStatus.IDLE,
  userTransactionsHistory: null,
  userTransactionsHistoryStatus: ResponseStatus.IDLE,
  userBalanceTopUpStatus: ResponseStatus.IDLE,
  userBalanceWithdrawStatus: ResponseStatus.IDLE,
  stripeKeysStatus: ResponseStatus.IDLE,
  clientSecret: null,
};

export const getUserWalletBalanceAsync = createAsyncThunk("wallet/getUserWalletBalanceAsync", async () => {
  const response = await getUserWalletBalance();

  return response.amount;
});

export const donateToUserAsync = createAsyncThunk("wallet/donateToUserAsync", async (data: RawDonateToUserRequest) => {
  return await donateToUser({ amount: convertDollarsToCents(data.amountInDollars), ...data });
});
export const getUserTransactionHistoryAsync = createAsyncThunk("wallet/getUserTransactionHistoryAsync", async () => {
  const response = await getUserTransactionHistory();

  return response.transactions.map((transaction) => new Transaction(transaction));
});

export const withdrawUserBalanceAsync = createAsyncThunk("wallet/withdrawUserBalanceAsync", async (amountInDollars: number) => {
  return await withdrawUserBalance({ amount: convertDollarsToCents(amountInDollars) });
});

export const topUpUserBalanceAsync = createAsyncThunk("wallet/topUpUserBalanceAsync", async (amountInDollars: number) => {
  return await topUpUserBalance({ amount: convertDollarsToCents(amountInDollars) });
});

export const getStripeKeysAsync = createAsyncThunk("wallet/getStripeKeysAsync", async (amountInDollars: number) => {
  return await getStripeKeys({ amount: convertDollarsToCents(amountInDollars) });
});

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    resetWithdrawStatus: (state) => {
      state.userBalanceWithdrawStatus = ResponseStatus.IDLE;
    },
    resetTopUpStatus: (state) => {
      state.userBalanceTopUpStatus = ResponseStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserWalletBalanceAsync.pending, (state) => {
        state.userBalanceStatus = ResponseStatus.LOADING;
      })
      .addCase(getUserWalletBalanceAsync.fulfilled, (state, action) => {
        state.userBalanceStatus = ResponseStatus.IDLE;
        state.userBalanceInCents = action.payload;
      })
      .addCase(getUserWalletBalanceAsync.rejected, (state) => {
        state.userBalanceStatus = ResponseStatus.FAILED;
      })
      .addCase(donateToUserAsync.pending, (state) => {
        state.donateToUserStatus = ResponseStatus.LOADING;
      })
      .addCase(donateToUserAsync.fulfilled, (state) => {
        state.donateToUserStatus = ResponseStatus.IDLE;
      })
      .addCase(donateToUserAsync.rejected, (state) => {
        state.donateToUserStatus = ResponseStatus.FAILED;
      })
      .addCase(getUserTransactionHistoryAsync.pending, (state) => {
        state.userTransactionsHistoryStatus = ResponseStatus.LOADING;
      })
      .addCase(getUserTransactionHistoryAsync.fulfilled, (state, action) => {
        state.userTransactionsHistoryStatus = ResponseStatus.IDLE;
        state.userTransactionsHistory = action.payload;
      })
      .addCase(getUserTransactionHistoryAsync.rejected, (state) => {
        state.userTransactionsHistoryStatus = ResponseStatus.FAILED;
      })
      .addCase(withdrawUserBalanceAsync.pending, (state) => {
        state.userBalanceWithdrawStatus = ResponseStatus.LOADING;
      })
      .addCase(withdrawUserBalanceAsync.fulfilled, (state) => {
        state.userBalanceWithdrawStatus = ResponseStatus.SUCCESS;
      })
      .addCase(withdrawUserBalanceAsync.rejected, (state) => {
        state.userBalanceWithdrawStatus = ResponseStatus.FAILED;
      })
      .addCase(topUpUserBalanceAsync.pending, (state) => {
        state.userBalanceTopUpStatus = ResponseStatus.LOADING;
      })
      .addCase(topUpUserBalanceAsync.fulfilled, (state) => {
        state.userBalanceTopUpStatus = ResponseStatus.SUCCESS;
      })
      .addCase(topUpUserBalanceAsync.rejected, (state) => {
        state.userBalanceTopUpStatus = ResponseStatus.FAILED;
      })
      .addCase(getStripeKeysAsync.pending, (state) => {
        state.stripeKeysStatus = ResponseStatus.LOADING;
      })
      .addCase(getStripeKeysAsync.fulfilled, (state, action) => {
        state.stripeKeysStatus = ResponseStatus.SUCCESS;
        state.clientSecret = action.payload.client_secret;
      })
      .addCase(getStripeKeysAsync.rejected, (state) => {
        state.stripeKeysStatus = ResponseStatus.FAILED;
        state.clientSecret = null;
      });
  },
});

export const walletSelectors = {
  userBalanceInDollars: (state: RootState) => convertCentsToDollars(state.wallet.userBalanceInCents),
  userBalanceStatus: (state: RootState) => state.wallet.userBalanceStatus,
  isUserBalanceFetched: (state: RootState) => state.wallet.userBalanceStatus === ResponseStatus.IDLE,
  userTransactionsHistory: (state: RootState) => state.wallet.userTransactionsHistory,
  userTransactionsHistoryStatus: (state: RootState) => state.wallet.userTransactionsHistoryStatus,
  userBalanceTopUpStatus: (state: RootState) => state.wallet.userBalanceTopUpStatus,
  userBalanceWithdrawStatus: (state: RootState) => state.wallet.userBalanceWithdrawStatus,
  stripeKeysStatus: (state: RootState) => state.wallet.stripeKeysStatus,
  clientSecret: (state: RootState) => state.wallet.clientSecret,
};

export const { resetWithdrawStatus, resetTopUpStatus } = walletSlice.actions;

export default walletSlice.reducer;
