import { SocialNetworkProvider, Platform } from "constants/App";
import { RequestMethod } from "client/BaseHttpClient";
import Client from "services/Client";
import AuthUtilsService from "services/AuthUtilsService";
import { UserRole } from "../types/users";

interface APIAuth {
  access_token: string;
  exp: number;
  refresh_token: string;
  token_type: string;
}

interface APILogin extends APIAuth {
  new_user: boolean;
}

export interface SocialNetworkRequest {
  access_token: string;
  access_token_secret?: string;
  provider: SocialNetworkProvider;
  device: {
    device_id: string;
    platform: Platform;
  };
}

export interface SocialNetworkResponse {
  access_token: string;
  refresh_token: string;
  token_type: "Bearer";
  exp: number;
  new_user: boolean;
  roles: UserRole;
  need_to_fill_paxer_profile: boolean;
}

export const sendSignInVerificationCode = (email: string): Promise<void> =>
  Client.doRequest("/api/v1/auth/passwordless/start", {
    method: RequestMethod.POST,
    omitDefaultErrorHandling: true,
    data: {
      email,
    },
  });

export const sendSignUpVerificationCode = (email: string): Promise<void> =>
  Client.doRequest("/api/v1/auth/passwordless/register", {
    method: RequestMethod.POST,
    omitDefaultErrorHandling: true,
    data: {
      email,
    },
  });

export const sendAuthVerificationCode = (email: string): Promise<void> =>
  Client.doRequest("/api/v1/auth/passwordless/auth", {
    method: RequestMethod.POST,
    omitDefaultErrorHandling: true,
    data: {
      email,
    },
  });

export const login = (username: string, otp: string): Promise<APILogin> =>
  Client.doRequest("/api/v1/auth/passwordless/token", {
    method: RequestMethod.POST,
    omitDefaultErrorHandling: true,
    data: {
      username,
      otp,
    },
  });

export const tryToUpdateAccessToken = (): Promise<APIAuth> =>
  Client.doRequest("/api/v1/auth/refresh", {
    method: RequestMethod.POST,
    data: {
      refresh_token: AuthUtilsService.data.refreshToken,
    },
  });

export const authSocialNetwork = (request: SocialNetworkRequest): Promise<SocialNetworkResponse> =>
  Client.doRequest("/auth/oauth2", {
    method: RequestMethod.POST,
    data: request,
  });
