import { UsersFilters } from "types/users";

export const convertFiltersToRaw = (filters: UsersFilters) => {
  return {
    ...filters,
    skills: filters.skills,
    min_rate: filters.rateFrom || null,
    max_rate: filters.rateTo || null,
    location_id: filters.locationId || null,
    min_stars: Number(filters.rating) || null,
  };
};
