export const headerHeight = "64px";
export const sidebarWidth = "240px";
export const footerHeight = "300px";

export const sidebarWidthMD = "64px";
export const sidebarWidthLG = "200px";
export const sidebarWidthXL = "240px";

export const streamFrameWidthXL = "352px";
export const streamFrameWidthLG = "296px";

export const unauthFooterHeight = "222px";

export const backgroundImageMargin = "-52px";

export const headerZIndex = "1100";
export const sidebarZIndex = "3";

export const xs = "0";
export const sm = "576px";
export const md = "768px";
export const lg = "1200px";
export const xl = "1440px";

// COLORS
export const white = "#ffffff";
export const black = "#000000";
// Neutrals
export const gray90 = "#101017";
export const gray80 = "#302f3a";
export const gray60 = "#626074";
export const gray40 = "#6f6f7c";
export const gray30 = "#9A9AAF";
export const gray20 = "#c8c8db";
export const gray15 = "#dadaec";
export const gray10 = "#e2e2ea";
export const gray5 = "#f0f0f9";

// Communication
export const success = "#74b566";
export const info = "#76bedb";
export const warning = "#e6af5f";
export const error = "#d82e42";

// Primary
export const accent = "#FF9315";
export const activeAccent = "#F18200";
export const activeDisabled = "#E6AF5F";
export const primary = "#f6f6fa";

// Icons
export const red = "#d82e42";
export const cyan = "#57bff7";
export const green = "#31d8b2";
export const blue = "#1682fe";
export const pink = "#e978de";

export const star = "#FFD600";
