import { RequestMethod } from "client/BaseHttpClient";
import { OrderCreationProps } from "slices/orderSlice";
import { Order, OrderProlongationRawData } from "types/orders";
import Client from "./Client";

export const createOrder = (data: OrderCreationProps): Promise<Order> =>
  Client.doRequest<Order>("/api/v1/orders", {
    method: RequestMethod.POST,
    data: {
      paxer: data.paxer,
      stream_start_time: data.streamStartTime,
      duration: data.durationInSeconds,
      description: data.description
    },
  });

export const getOrder = (orderId: string): Promise<Order> =>
  Client.doRequest<Order>(`/api/v1/order/${orderId}`, {
    method: RequestMethod.GET
  });

export const cancelOrder = (orderId: string): Promise<Order> =>
  Client.doRequest<Order>(`/api/v1/orders/${orderId}/item:cancel`, {
    method: RequestMethod.POST
  });

export const payOrder = (orderId: string): Promise<Order> =>
  Client.doRequest<Order>(`/api/v1/orders/${orderId}/item:payFor`, {
    method: RequestMethod.POST
  });

export const fetchOrderProlongationAvailability = (orderId: string): Promise<OrderProlongationRawData> =>
  Client.doRequest(`/api/v1/orders/${orderId}/prolongation:availability`, {
    method: RequestMethod.GET,
  });

export const prolongateOrder = (orderId: string) =>
  Client.doRequest<Order>(`/api/v1/orders/${orderId}/prolongation:pay`, {
    method: RequestMethod.POST,
  });
