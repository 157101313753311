import { RequestMethod } from "client/BaseHttpClient";
import Client from "services/Client";
import axios from "axios";
import { ChatUserData, UsersFilters } from "types/users";
import { PrivateProfileRawData, PublicProfileRawData } from "types/profile";
import { Order, OrderStatus } from "types/orders";
import { convertFiltersToRaw } from "utils/Filters";
import { Pagination } from "types/request";

interface APIProfiles {
  profiles: PrivateProfileRawData[];
}

interface APIAvatarURL {
  avatar_upload_url: string;
  avatar_url: string;
}

export const getCurrentUserProfile = (): Promise<PrivateProfileRawData> =>
  Client.doRequest("/api/v1/profiles/me", {
    method: RequestMethod.GET,
  });

export const setCurrentUserProfile = (data): Promise<PrivateProfileRawData> =>
  Client.doRequest("/api/v1/profiles/me", {
    method: RequestMethod.POST,
    data,
  });

export const getCurrentChatUser = (): Promise<ChatUserData> =>
  Client.doRequest("/api/v1/me/chats", {
    method: RequestMethod.GET
  });

export const getPaxerProfile = (id: string): Promise<PrivateProfileRawData> =>
  Client.doRequest(`/api/v1/profiles/id/${id}/profile`, {
    method: RequestMethod.GET,
  });

export const getCurrentUserOrders = (statuses: OrderStatus[] = []): Promise<Order[]> => {
  const queryParams = statuses.reduce((acc, status, i, array) =>
    acc + `${i === 0 ? '?' : ''}status[]=${status}${i < array.length - 1 ? '&' : ''}`, '')

  return Client.doRequest(`/api/v1/me/orders${queryParams}`, {
    method: RequestMethod.GET,
  });
}

export const getPaxerList = (params?: Pagination): Promise<APIProfiles> =>
  Client.doRequest(`/api/v1/profiles${
    params ?
      `?${Object.entries(params).map(([key, value]) => `${key}=${value}`).join("&")}` :
      ''}`, {
    method: RequestMethod.GET,
  });

export const getFilteredPaxerList = (filters: Pagination & UsersFilters): Promise<APIProfiles> => {
  const rawFilters = convertFiltersToRaw(filters);

  return Client.doRequest("/api/v1/profiles", {
    method: RequestMethod.POST,
    data: rawFilters,
  });
};

export const getAvatarURL = (): Promise<APIAvatarURL> =>
  Client.doRequest("/api/v1/profiles/avatar", {
    method: RequestMethod.POST,
  });

export const removeAvatarURL = (): Promise<APIAvatarURL> =>
  Client.doRequest("/api/v1/me/profile/avatar", {
    method: RequestMethod.DELETE,
  });

// TODO Now if use doRequest for setAvatar: conflict of Signature query string parameter and the Authorization header
export const setAvatar = (avatar_upload_url, avatar) => {
  const configs = {
    headers: {
      "Content-Type": avatar.type,
    },
  };

  return axios.put(avatar_upload_url, avatar, configs).then((response) => {
    if (response.status === 200) {
      return response?.data;
    }
    return null;
  });
};

export const getTopUsers = (): Promise<PublicProfileRawData[]> =>
  Client.doRequest(`/api/v1/top/paxers`, {
    method: RequestMethod.GET,
  });
