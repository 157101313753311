import { ResponseStatus } from "types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";
import { getAutocomletedLocationList } from "services/LocationService";
import { Location } from "models/Location";

export interface LocationState {
  autocompletedLocationListStatus: ResponseStatus;
  autocompletedLocationList: Location[];
}

const initialState: LocationState = {
  autocompletedLocationListStatus: ResponseStatus.IDLE,
  autocompletedLocationList: [],
};

export const getAutocomletedLocationListAsync = createAsyncThunk(
  "location/getAutocomletedLocationListAsync",
  async (locationText: string) => {
    const locationList = await getAutocomletedLocationList(locationText);

    return locationList?.items?.map((location) => new Location(location));
  },
);

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAutocomletedLocationListAsync.pending, (state) => {
        state.autocompletedLocationListStatus = ResponseStatus.LOADING;
      })
      .addCase(getAutocomletedLocationListAsync.fulfilled, (state, action) => {
        state.autocompletedLocationListStatus = ResponseStatus.IDLE;
        state.autocompletedLocationList = action.payload;
      })
      .addCase(getAutocomletedLocationListAsync.rejected, (state) => {
        state.autocompletedLocationListStatus = ResponseStatus.FAILED;
      });
  },
});

export const locationSelectors = {
  autocompletedLocationList: (state: RootState) => state.location.autocompletedLocationList,
  autocompletedLocationListStatus: (state: RootState) => state.location.autocompletedLocationListStatus,
};

export default locationSlice.reducer;
